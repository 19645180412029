@import "src/scss/module";

.insights {

  &__sidebar {
    position: relative;
    background-color: $light;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2 {
      white-space: nowrap;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: -32px;
      left: 0px;

      @include media-breakpoint-up(lg) {
        writing-mode: vertical-lr;
        transform: rotate(180deg) translateX(100%);
        top: initial;
        bottom: -8px;
        left: -9px;
      }

      &:before {
        content: '';
        display: block;
        height: 5px;
        width: 35px;
        margin-right: 10px;

        @include media-breakpoint-up(lg) {
          height: 110px;
          width: 5px;
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }
  }

  &__socials {

    a {
      width: 38px;
      height: 38px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .3s;

      svg {
        transition: .3s;
      }

      &:hover {
        svg {
          transform: translateY(-.5rem);
        }
      }
    }
  }

  @each $name, $color in $brand-colors {
    &[class*=variant-#{$name}] svg {
      fill: $color;
    }

    &[class*=variant-#{$name}] h2:before {
      background-color: $color;
    }

    &[class*=variant-#{$name}] &__sidebar a:hover {
      color: $color;
    }
  }
}