@import "src/scss/module";

.specialisms {
  @include media-breakpoint-up(md) {
    h2 {
      writing-mode: vertical-lr;
      transform: rotate(180deg) translateX(100%);
      position: absolute;
      bottom: -8px;
      left: -7px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 3;

      &:before {
        content: "";
        display: block;
        background-color: $primary;
        height: 40px;
        width: 5px;
        margin-bottom: 20px;
      }
    }
  }

  &__content {
    border: 1px solid #d3d3d3;
    position: relative;
    margin-top: 1rem;

    @include media-breakpoint-up(md) {
      min-height: 382px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    :global {
      .dropdown {
        background-size: contain;
        background-repeat: no-repeat;
        max-width: 400px;
        margin: auto;
        transition: 0.3s;

        @include media-breakpoint-up(md) {
          transform: translateY(-1rem);
          min-height: 382px;
        }

        &.show {
          border-radius: 5px;

          .dropdown-toggle {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &[data-dropdown="technology"] {
          background-image: url("/assets/specialisms/technology-bg.png");

          .dropdown-menu {
            background-image: url("/assets/specialisms/technology-dropdown-bg.png");
          }
        }

        &[data-dropdown="insurance"] {
          background-image: url("/assets/specialisms/insurance-bg.png");

          .dropdown-menu {
            background-image: url("/assets/specialisms/insurance-dropdown-bg.png");
          }
        }

        &[data-dropdown="banking"] {
          background-image: url("/assets/specialisms/banking-bg.png");

          .dropdown-menu {
            background-image: url("/assets/specialisms/banking-dropdown-bg.png");
          }
        }

        .dropdown-toggle {
          width: 100%;
          height: 62px;
          display: flex;
          justify-content: space-between;
          background-color: $primary !important;
          border-color: $primary !important;
          font-size: $h5-font-size;
          transition: 0.3s;

          &.show {
            color: $white;
            background-color: $secondary !important;
            border-color: $secondary !important;

            svg {
              display: none;
            }
          }

          &:after {
            display: none;
          }

          &:hover {
                 border: 10px solid transparent !important;

          }
        }

        .dropdown-menu {
          background-size: contain;
          background-position: bottom right;
          background-repeat: no-repeat;
          background-color: $secondary;
          border-color: $secondary;
          color: $white;
          width: 100%;
          margin-top: -8px;
          border-radius: 0 0 5px 5px;

          a {
            color: $white;

            &:hover,
            &:focus,
            &.active,
            &:active {
              color: $primary;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
