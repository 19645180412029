@import "src/scss/module";

.awards {

  a {
    color: $primary;
  }

  img {
    //filter: brightness(0);
    //opacity: 0.4;
    transition: 1s;

    &:hover {
      scale: 1.1;
    }
  }

  &[class*=secondary] {
    a {
      color: $secondary;
    }
  }

  figcaption {
    color: #444444;
    font-size: $font-size-sm;
  }
}