@import "src/scss/module";

.card {
  border-radius: 0;
  border: 0;
  border-bottom: 10px solid #F6F4FA;
  transition: .75s;
  background-color: $white;

  i {
    position: relative;
    font-style: normal;
    font-size: 18px;
    color: #ccc;

    span {
      display: inline-block;
      border-right: 3px solid #ccc;
      padding: 0 15px 0 20px;
      margin: 0 15px 0 0;
    }

    &:before {
      content: "";
      width: 8px;
      height: 8px;
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      left: 0;
      background: $primary;
      animation: pulse 2s infinite;
      border-radius: 100%;
    }
  }

  & > a {
    overflow: hidden;
    height: 265px;

    @include media-breakpoint-between(md, xl) {
      height: 230px;
    }

    & > img {
      height: 100%;
      border-radius: 0;
      transition: 2s !important;
      object-fit: cover;
    }
  }

  :global {

    .badge {
      position: absolute;
      top: 0;
      right: 0;
      margin: $spacer;
      border-radius: 100px;
      font-size: 14px;
      text-decoration: none;
      z-index: 9;
    }

    .card-body {
      display: flex;
      flex-direction: column;

      p {
        flex-grow: 1;
      }
    }

    .card-title {
      font-weight: $font-weight-semibold;
      line-height: 30px;
      min-height: 90px;
      transition: .3s;
      color: $secondary;
      text-decoration: none;
    }
  }

  p {
    font-size: 16px;
    line-height: 31px;
  }

  .btn.btn-outline-secondary {
    transition: .3s;
    &:before {
      transition: .3s;
    }
  }

  &:hover {
    background-color: #F6F4FA;

    & > a > img {
      scale: 1.1;
    }

    :global {
      .card-title {
        color: $primary
      }

     .btn.btn-outline-secondary {
       color: $primary !important;
       &:before {
         background-color: $primary !important;
       }
     }
    }
  }
}