@import "src/scss/module";

.values {

  [class=carousel-control-prev] {
    right: auto;
    left: 0;
    top: 50%;
    transform: translate(-50%, -100%);
  }

  [class=carousel-control-next] {
    right: auto;
    left: 0;
    top: 50%;
    transform: translate(-50%, 0%);
  }
}

.card {
  flex-direction: row;
  height: 100%;
  min-height: 500px;
  color: $white;
  background-color: $primary;

  & > :nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: 60%;
    flex-grow: 0;
    flex-shrink: 1;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    font-size: 1.5rem;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
    min-height: 150px;

    @include media-breakpoint-up(md) {
      font-size: 2.5rem;
      clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
    }

    @include media-breakpoint-up(lg) {
      flex-basis: 50%;
    }

    @include media-breakpoint-up(xl) {
      flex-basis: 40%;
    }

    &:before {
      content: '';
      display: block;
      background-color: rgba(0,0,0,.2);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    img {
      width: 65px;
      height: 65px;
      object-fit: contain;
      flex-grow: 0;

      @include media-breakpoint-up(sm) {
        width: 90px;
        height: 90px;
      }

      @include media-breakpoint-up(md) {
        width: 122px;
        height: 122px;
      }
    }
  }

  & > :nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 1px;
    flex-grow: 1;

    @include media-breakpoint-up(md) {
      font-size: $h5-font-size;
      line-height: 2rem;
    }
  }
}