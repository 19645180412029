@import "src/scss/module";

.heading {
  font-weight: $font-weight-bold;
  padding-bottom: .5em;
  margin-bottom: 1em;


  br {
    display: none;

    @include media-breakpoint-up(sm) {
      display: initial;
    }
  }

  &__rule {
    display: block;
    height: 5px;
    width: 0;
    position: absolute;
    right: 0;
    transition: 1.5s ease-in;
  }

  &[class*=white] {
    color: $white;
  }

  &[class*=white] &__rule {
    background-color: $white;
  }

  &[class*=primary] &__rule {
    background-color: $primary;
  }

  &[class*=secondary] &__rule {
    background-color: $secondary;
  }

  &[class*=quaternary] &__rule {
    background-color: $quaternary;
  }
}